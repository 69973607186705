<template>
  <div class="price-report">
    <div>
      <ai-breadcrumb />
      <div class="new-button">
        <guide class="guide-button" />
      </div>
    </div>
    <div v-loading="queryLoading" class="search-content">
      <el-form class="search-form" :inline="true" size="small" @submit.native.prevent>
        <el-form-item class="search-form-gutter" :label="$t('analysis.platform')">
          <el-select style="width: 88px" v-model="listQuery.platform" @change="changePlatform" filterable>
            <el-option v-for="item in platformList" :key="item.key" :label="item.value" :value="item.key"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="search-form-gutter" :label="$t('analysis.date')">
          <el-select style="width: 118px" v-model="currentDate" filterable>
            <el-option v-for="item in dateList" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="search-form-gutter" :label="$t('report.target')">
          <el-radio-group v-model="listQuery.targetType" @change="changeTarget" class="addition-vs">
            <el-radio-button v-for="item in targetTypeList" :key="item.key" :label="item.key">{{ item.value
            }}</el-radio-button>
          </el-radio-group>
          <el-select style="width: 149px" v-model="listQuery.target" filterable>
            <el-option v-for="item in targetList" :key="item.key" :label="item.value" :value="item.key"></el-option>
          </el-select>
          <label class="date-vs">VS</label>
          <el-select style="width: 149px" v-model="listQuery.compareTarget" filterable>
            <el-option v-for="item in targetList" :key="item.key" :label="item.value" :value="item.key"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="search-form-gutter" :label="$t('report.product')">
          <el-select style="width: 105px" :placeholder="$t('common.all')" v-model="listQuery.product"
            @change="changeProduct" filterable>
            <el-option v-for="item in productList" :key="item.key" :label="item.value" :value="item.key"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="search-form-gutter" :label="$t('report.season')">
          <el-select style="width: 135px" :placeholder="$t('common.all')" v-model="listQuery.season" clearable filterable>
            <el-option v-for="item in seasonList" :key="item.key" :label="item.value" :value="item.key"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="search-form-gutter" :label="$t('report.category')">
          <el-select style="width: 165px" :placeholder="$t('common.all')" v-model="listQuery.category" clearable
            filterable>
            <el-option v-for="item in categoryList" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="search-form-gutter">
          <el-button :disabled="!currentDate || !listQuery.target || !listQuery.compareTarget
            " size="small" :circle="true" type="primary" @click="fetchData" icon="el-icon-search"></el-button>
        </el-form-item>
        <el-form-item class="search-form-gutter">
          <el-button :disabled="!currentDate || !listQuery.target || !listQuery.compareTarget
            " class="excel-dl-btn" size="small" :circle="true" @click="handleDownload"
            icon="el-icon-download"></el-button>
        </el-form-item>
      </el-form>
    </div>
    <div v-loading="chartLoading" class="data-content">
      <div v-if="noData" style="margin-top: 10px; padding: 20px">No Data</div>
      <div :class="{ hidden: noData }">
        <el-row>
          <el-col :span="12">
            <div id="left-chart-real-tag" />
          </el-col>
          <el-col :span="12">
            <div id="right-chart-real-tag" />
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <div id="left-chart-discount" />
          </el-col>
          <el-col :span="12">
            <div id="right-chart-discount" />
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="item-content" :class="{ hidden: noData }">
      <el-form class="search-form" :inline="true" size="small" @submit.native.prevent>
        <el-form-item class="search-form-gutter" :label="$t('report.item')">
          <el-select style="width: 175px" v-model="currentItem" filterable>
            <el-option v-for="item in graphData.items" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-row>
        <el-col :span="6">
          <div id="left-chart-item-price" />
        </el-col>
        <el-col :span="6">
          <div id="left-chart-item-discount" />
        </el-col>
        <el-col :span="6">
          <div id="right-chart-item-price" />
        </el-col>
        <el-col :span="6">
          <div id="right-chart-item-discount" />
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { downloadFileAuth, serialize } from "@/utils/download-file";
import { aiApiRoot } from "@/utils/server";
import { getToken } from "@/utils/auth";
import {
  fetchUserBrandType,
  fetchUserBrands,
  fetchRecords,
} from "@/api/analysis";
import { fetchCategory, fetchPriceReportData } from "@/api/report";
import AiBreadcrumb from "@/components/AiBreadcrumb";
import Guide from "@/components/Guide";
export default {
  components: {
    AiBreadcrumb,
    Guide,
  },
  data() {
    return {
      currentDate: "",
      platformList: [
        { key: "tmall", value: "TMall" },
        { key: "tiktok", value: "Tiktok" },
      ],
      targetTypeList: [
        { key: "group", value: this.$t("report.group") },
        { key: "brand", value: this.$t("common.brand") },
      ],
      targetList: [],
      dateList: [],
      seasonList: [
        { key: "当年", value: this.$t("report.currentYear") },
        { key: "往年", value: this.$t("report.previousYear") },
      ],
      productList: [
        { key: "fashion", value: this.$t("report.fashion") },
        { key: "etc", value: this.$t("report.etc") },
      ],
      categoryList: [],
      itemList: [],
      listQuery: {
        platform: "tmall",
        targetType: "group",
        target: "",
        compareTarget: "",
        date: "",
        season: "",
        product: "fashion",
        category: "",
      },
      graphData: {
        first: {},
        second: {},
        items: [],
      },
      currentItem: "",
      leftRealTagChart: null,
      rightRealTagChart: null,
      leftDiscountChart: null,
      rightDiscountChart: null,
      leftItemPriceChart: null,
      rightItemPriceChart: null,
      leftItemDiscountChart: null,
      rightItemDiscountChart: null,
      chartLoading: false,
      queryLoading: false,
      hasFirstLoad: false,
    };
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.leftRealTagChart = echarts.init(
      document.getElementById("left-chart-real-tag")
    );
    this.rightRealTagChart = echarts.init(
      document.getElementById("right-chart-real-tag")
    );
    this.leftDiscountChart = echarts.init(
      document.getElementById("left-chart-discount")
    );
    this.rightDiscountChart = echarts.init(
      document.getElementById("right-chart-discount")
    );
    this.leftItemPriceChart = echarts.init(
      document.getElementById("left-chart-item-price")
    );
    this.rightItemPriceChart = echarts.init(
      document.getElementById("right-chart-item-price")
    );
    this.leftItemDiscountChart = echarts.init(
      document.getElementById("left-chart-item-discount")
    );
    this.rightItemDiscountChart = echarts.init(
      document.getElementById("right-chart-item-discount")
    );

    this.fetchDates();
    this.fetchCategoryList();
  },
  beforeUnmout() {
    window.removeEventListener("resize", this.handleResize);
    this.leftRealTagChart.dispose();
    this.rightRealTagChart.dispose();
    this.leftDiscountChart.dispose();
    this.rightDiscountChart.dispose();
    this.leftItemPriceChart.dispose();
    this.rightItemPriceChart.dispose();
    this.leftItemDiscountChart.dispose();
    this.rightItemDiscountChart.dispose();
  },
  methods: {
    initGraphData() {
      this.currentItem = "";
      this.graphData = {
        first: {},
        second: {},
        items: [],
      };
    },
    handleResize() {
      if (this.leftRealTagChart) {
        this.leftRealTagChart.resize();
      }
      if (this.rightRealTagChart) {
        this.rightRealTagChart.resize();
      }
      if (this.leftDiscountChart) {
        this.leftDiscountChart.resize();
      }
      if (this.rightDiscountChart) {
        this.rightDiscountChart.resize();
      }
      if (this.leftItemPriceChart) {
        this.leftItemPriceChart.resize();
      }
      if (this.rightItemPriceChart) {
        this.rightItemPriceChart.resize();
      }
      if (this.leftItemDiscountChart) {
        this.leftItemDiscountChart.resize();
      }
      if (this.rightItemDiscountChart) {
        this.rightItemDiscountChart.resize();
      }
    },
    fetchDates() {
      this.queryLoading = true;
      this.dateList = [];
      this.currentDate = "";
      fetchRecords({
        type: "sales",
        period: "week",
        platform: this.listQuery.platform,
        limit: -1,
      })
        .then((response) => {
          if (response.success) {
            response.result &&
              response.result.items &&
              response.result.items.forEach((e) => {
                this.dateList.push({
                  value: e.date,
                  label: e.linkDate,
                });
              });
            if (this.dateList && this.dateList.length > 0) {
              this.currentDate = this.dateList[0].value;
            }
          }
          this.queryLoading = false;
        })
        .catch(() => {
          this.queryLoading = false;
        });
    },
    fetchTargets() {
      this.queryLoading = true;
      this.targetList = [];
      this.listQuery.target = "";
      this.listQuery.compareTarget = "";
      if (this.listQuery.targetType === "group") {
        fetchUserBrandType()
          .then((response) => {
            if (response.success) {
              this.targetList = response.result.items
                ? response.result.items.map((t) => {
                  return { key: t, value: t };
                })
                : [];
              if (this.targetList && this.targetList.length > 0) {
                this.listQuery.target = this.targetList[0].key;
                if (this.targetList.length > 1) {
                  this.listQuery.compareTarget = this.targetList[1].key;
                } else {
                  this.listQuery.compareTarget = this.targetList[0].key;
                }
                this.fetchData(true);
              }
            }
            this.queryLoading = false;
          })
          .catch(() => {
            this.queryLoading = false;
          });
      } else {
        fetchUserBrands({
          platform: this.listQuery.platform,
          dates: this.currentDate,
        })
          .then((response) => {
            if (response.success) {
              this.targetList = response.result.items
                ? response.result.items.map((t) => {
                  return { key: t.id + "", value: t.name };
                })
                : [];
              if (this.targetList && this.targetList.length > 0) {
                this.listQuery.target = this.targetList[0].key;
                if (this.targetList.length > 1) {
                  this.listQuery.compareTarget = this.targetList[1].key;
                } else {
                  this.listQuery.compareTarget = this.targetList[0].key;
                }
              }
            }
            this.queryLoading = false;
          })
          .catch(() => {
            this.queryLoading = false;
          });
      }
    },
    fetchCategoryList() {
      this.queryLoading = true;
      this.listQuery.category = "";
      fetchCategory(this.listQuery.product)
        .then((response) => {
          if (response.success) {
            this.categoryList = response.result ? response.result : [];
          }
          this.queryLoading = false;
        })
        .catch(() => {
          this.queryLoading = false;
        });
    },
    fetchData(firstLoad) {
      if (
        !this.currentDate ||
        !this.listQuery.target ||
        !this.listQuery.compareTarget
      ) {
        return;
      }
      if (firstLoad === true) {
        if (this.hasFirstLoad) {
          return;
        }
        this.hasFirstLoad = true;
      }
      this.listQuery.date = this.currentDate;
      this.chartLoading = true;
      this.initGraphData();
      fetchPriceReportData(this.listQuery)
        .then((response) => {
          if (response.success) {
            this.graphData = response.result;
            if (
              this.graphData &&
              this.graphData.items &&
              this.graphData.items.length > 0
            ) {
              this.currentItem = this.graphData.items[0];
              this.$nextTick(() => {
                this.drawRealTagChart();
                this.drawDiscountChart();
              });
            }
          }
          this.chartLoading = false;
        })
        .catch(() => {
          this.chartLoading = false;
        });
    },
    drawRealTagChart() {
      let generateChart = (chart, data, max) => {
        let option = {
          title: {
            text: this.$t("report.midPrice"),
            padding: [5, 10],
            textStyle: {
              fontSize: 16,
            },
          },
          grid: {
            top: 40,
            left: 30,
            bottom: 10,
            right: 20,
            containLabel: true,
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
              crossStyle: {
                color: "#999",
              },
            },
          },
          legend: {
            data: [
              this.$t("report.realMidPrice"),
              this.$t("report.tagMidPrice"),
            ],
          },
          xAxis: {
            type: "category",
            data: this.graphData.items,
            axisTick: {
              interval: 0,
            },
            axisLabel: {
              interval: 0,
              rotate: 45,
            },
          },
          yAxis: [
            {
              type: "value",
              axisLabel: {
                formatter: "{value}",
              },
              max: max,
            },
          ],
          series: [
            {
              name: this.$t("report.realMidPrice"),
              type: "bar",
              data: data["realPriceMid"],
            },
            {
              name: this.$t("report.tagMidPrice"),
              type: "bar",
              data: data["tagPriceMid"],
            },
          ],
        };
        chart.setOption(option, true);
      };
      let max = 0;
      let maxValue = (a) => {
        if (a && a.length > 0) {
          a.forEach((e) => {
            if (max < e) {
              max = e;
            }
          });
        }
      };
      maxValue(this.graphData.first["realPriceMid"]);
      maxValue(this.graphData.first["tagPriceMid"]);
      maxValue(this.graphData.second["realPriceMid"]);
      maxValue(this.graphData.second["tagPriceMid"]);
      generateChart(this.leftRealTagChart, this.graphData.first, max);
      generateChart(this.rightRealTagChart, this.graphData.second, max);
    },
    drawDiscountChart() {
      let generateChart = (chart, data) => {
        let option = {
          title: {
            text: this.$t("report.discount"),
            padding: [5, 10],
            textStyle: {
              fontSize: 16,
            },
          },
          color: ["#73c0de"],
          grid: {
            top: 40,
            left: 30,
            bottom: 10,
            right: 20,
            containLabel: true,
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
              crossStyle: {
                color: "#999",
              },
            },
            valueFormatter: (value) => value + "%",
          },
          legend: {
            data: [this.$t("report.discount")],
          },
          xAxis: {
            type: "category",
            data: this.graphData.items,
            axisTick: {
              interval: 0,
            },
            axisLabel: {
              interval: 0,
              rotate: 45,
            },
          },
          yAxis: [
            {
              type: "value",
              axisLabel: {
                formatter: "{value} %",
              },
              max: max,
            },
          ],
          series: [
            {
              name: this.$t("report.discount"),
              type: "bar",
              data: data["discountRate"],
            },
          ],
        };
        chart.setOption(option, true);
      };
      let max = 0;
      let maxValue = (a) => {
        if (a && a.length > 0) {
          a.forEach((e) => {
            if (max < e) {
              max = e;
            }
          });
        }
      };
      maxValue(this.graphData.first["discountRate"]);
      maxValue(this.graphData.second["discountRate"]);
      generateChart(this.leftDiscountChart, this.graphData.first, max);
      generateChart(this.rightDiscountChart, this.graphData.second, max);
    },
    drawItemPriceChart() {
      let generateChart = (chart, data, i, max) => {
        let d = [
          {
            data: data["priceMin"] ? data["priceMin"][i] : 0,
            name: "MIN",
            label: this.$t("report.min"),
          },
          {
            data: data["priceMax"] ? data["priceMax"][i] : 0,
            name: "MAX",
            label: this.$t("report.max"),
          },
          {
            data: data["realPriceMid"] ? data["realPriceMid"][i] : 0,
            name: "MID(R)",
            label: this.$t("report.midr"),
          },
          {
            data: data["tagPriceMid"] ? data["tagPriceMid"][i] : 0,
            name: "MID(T)",
            label: this.$t("report.midt"),
          },
        ];
        d.sort(function (a, b) {
          return a.data - b.data;
        });
        let option = {
          title: {
            text: this.$t("report.priceRange"),
            padding: [5, 10],
            textStyle: {
              fontSize: 16,
            },
          },
          grid: {
            top: 40,
            left: 10,
            bottom: 10,
            right: 120,
            containLabel: true,
          },
          tooltip: {
            show: false,
            // trigger: "axis",
            // axisPointer: {
            //   type: "cross",
            //   crossStyle: {
            //     color: "#999",
            //   },
            // },
            // formatter: "{b0}: {c0}<br />{b1}: {c1}",
          },
          legend: {
            show: false,
          },
          xAxis: {
            type: "category",
            data: [this.graphData.items[i]],
            axisTick: {
              interval: 0,
            },
            axisLabel: {
              interval: 0,
            },
          },
          yAxis: [
            {
              type: "value",
              axisLabel: {
                formatter: "{value}",
              },
              max: max,
            },
          ],
          series: [],
        };
        for (let j = 0; j < d.length; j++) {
          let currentData = j === 0 ? d[j].data : d[j].data - d[j - 1].data;
          option.series.push({
            name: d[j].name,
            type: "bar",
            stack: "Price",
            data: [currentData],
            barMaxWidth: "40%",
            label: {
              show: true,
              formatter: d[j].data + "",
            },
            markLine: {
              data: [
                {
                  type: "max",
                },
              ],
              label: {
                formatter: d[j].label,
              },
              symbol: "none",
            },
          });
        }
        chart.setOption(option, true);
      };
      let idx = 0;
      for (let i = 0; i < this.graphData.items.length; i++) {
        if (this.currentItem === this.graphData.items[i]) {
          idx = i;
          break;
        }
      }
      let max = 0;
      let maxValue = (e) => {
        if (e && e[idx] && max < e[idx]) {
          max = e[idx];
        }
      };
      maxValue(this.graphData.first["priceMax"]);
      maxValue(this.graphData.second["priceMax"]);
      generateChart(this.leftItemPriceChart, this.graphData.first, idx, max);
      generateChart(this.rightItemPriceChart, this.graphData.second, idx, max);
    },
    drawItemDiscountChart() {
      let generateChart = (chart, data, i) => {
        let current = data["discountRate"] ? data["discountRate"][i] : 0;
        let option = {
          title: {
            text: this.$t("report.discount"),
            padding: [5, 10],
            textStyle: {
              fontSize: 16,
            },
          },
          color: ["#73c0de", "#9a60b4"],
          grid: {
            top: 40,
            left: 30,
            bottom: 10,
            right: 20,
            containLabel: true,
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
              crossStyle: {
                color: "#999",
              },
            },
          },
          series: [
            {
              name: "Discount",
              type: "pie",
              radius: ["40%", "70%"],
              data: [current, 100 - current],
              label: {
                show: true,
                position: "center",
                fontWeight: "bold",
                fontSize: 14,
                formatter: () => {
                  return current + " %";
                },
              },
            },
          ],
        };
        chart.setOption(option, true);
      };
      let idx = 0;
      for (let i = 0; i < this.graphData.items.length; i++) {
        if (this.currentItem === this.graphData.items[i]) {
          idx = i;
          break;
        }
      }

      generateChart(this.leftItemDiscountChart, this.graphData.first, idx);
      generateChart(this.rightItemDiscountChart, this.graphData.second, idx);
    },
    handleDownload() {
      const loading = this.$loading({
        lock: true,
        text: this.$t("common.preparingData"),
      });
      let query = this.listQuery;
      query.date = this.currentDate;
      downloadFileAuth(
        aiApiRoot + "/v1/report/price/export?" + serialize(query),
        query.date + "_price_report_" + Date.now() + ".xlsx",
        getToken(),
        () => {
          loading.close();
        }
      );
    },
    changePlatform() {
      this.fetchDates();
    },
    changeProduct() {
      this.fetchCategoryList();
    },
    changeTarget() {
      this.fetchTargets();
    },
  },
  watch: {
    currentItem() {
      this.drawItemPriceChart();
      this.drawItemDiscountChart();
    },
    currentDate() {
      this.fetchTargets();
    },
  },
  computed: {
    noData() {
      return (
        !this.graphData ||
        !this.graphData.items ||
        this.graphData.items.length === 0
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.price-report {
  .new-button {
    text-align: right;
    width: 100%;
    margin: 10px 0;
  }

  .search-content {
    background-color: #fff;
    padding: 10px 0 0 10px;
    margin-bottom: 10px;

    .el-form-item {
      margin-bottom: 10px;
    }

    .search-form {
      width: auto;

      .search-form-gutter {
        margin-right: 10px;
      }
    }

    .addition-vs {
      vertical-align: inherit;
      margin-right: 12px;
    }

    .date-vs {
      vertical-align: middle;
      font-size: 14px;
      color: #606266;
      margin-left: 12px;
      margin-right: 12px;
    }
  }

  .data-content {
    background-color: #fff;
    min-height: 30px;
    text-align: center;
    font-size: 14px;
    color: #606266;

    /deep/ .el-row {
      padding-top: 10px;
    }

    .hidden {
      visibility: hidden;
      height: 0px;

      div {
        overflow: hidden;
      }
    }
  }

  .item-content {
    margin-top: 10px;
    padding: 10px 0 0 10px;
    background-color: #fff;
  }

  .item-content.hidden {
    visibility: hidden;
    height: 0px;

    div {
      overflow: hidden;
    }
  }

  #left-chart-real-tag {
    width: 100%;
    min-height: 248px;
    height: calc(100vh - 100px - 52.5px - 52.5px - 328px - 10px - 10px);
  }

  #right-chart-real-tag {
    width: 100%;
    min-height: 248px;
    height: calc(100vh - 100px - 52.5px - 52.5px - 328px - 10px - 10px);
  }

  #left-chart-discount {
    width: 100%;
    min-height: 248px;
    height: calc(100vh - 100px - 52.5px - 52.5px - 328px - 10px - 10px);
  }

  #right-chart-discount {
    width: 100%;
    min-height: 248px;
    height: calc(100vh - 100px - 52.5px - 52.5px - 328px - 10px - 10px);
  }

  #left-chart-item-price {
    width: 100%;
    min-height: 248px;
    height: calc(100vh - 100px - 52.5px - 52.5px - 328px - 10px - 10px);
  }

  #right-chart-item-price {
    width: 100%;
    min-height: 248px;
    height: calc(100vh - 100px - 52.5px - 52.5px - 328px - 10px - 10px);
  }

  #left-chart-item-discount {
    width: 100%;
    min-height: 248px;
    height: calc(100vh - 100px - 52.5px - 52.5px - 328px - 10px - 10px);
  }

  #right-chart-item-discount {
    width: 100%;
    min-height: 248px;
    height: calc(100vh - 100px - 52.5px - 52.5px - 328px - 10px - 10px);
  }
}
</style>